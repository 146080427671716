import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePreview from "../components/articlePreview"
import Banner from "../components/Banner"
import {
  getCookie,
  getBookMarkList,
  getUser,
  authenticate,
  isAuth,
} from "../components/helpers"

import {REACT_APP_WITH_ADD} from './config';


const Wedding = ({ data, pageContext }) => {
  const articles = data.articles.nodes
  const child = data.tags.edges[0].node.relationships.parent
  // console.log('pageContext', pageContext);
  // console.log('Data Length', articles.length);
  const articleCount = articles.length
  console.log("parent", child)
  // console.log("parent2", parent)
  // const articleArray = getBookMarkList();
  // let articleArray = [ "5fde5329-e2fb-54c7-93b1-8bc5263c062d", "11fb3314-bbbf-55ab-8d13-d608133b2d24", "15201125-979b-474a-b77a-c36c2b6e3c54", "5457bbf5-1337-45e1-b2b3-0029701fbfd3" ];

  const [values, setValues] = useState({
    // isMarked: (articleArray.indexOf(post.drupal_id) != -1 && isAuth()) ? true  : false
    // articleArray:["5fde5329-e2fb-54c7-93b1-8bc5263c062d", "11fb3314-bbbf-55ab-8d13-d608133b2d24", "15201125-979b-474a-b77a-c36c2b6e3c54", "5457bbf5-1337-45e1-b2b3-0029701fbfd3"]
    articleArray: getBookMarkList(),
    limit: 3,
    range : 5
  })

 

  const { articleArray } = values
  const { range } = values
  const { limit } = values
  useEffect(() => {
    //let articleArray = ["5fde5329-e2fb-54c7-93b1-8bc5263c062d", "11fb3314-bbbf-55ab-8d13-d608133b2d24", "15201125-979b-474a-b77a-c36c2b6e3c54", "5457bbf5-1337-45e1-b2b3-0029701fbfd3"];
    // let articleArray = getBookMarkList();
    setValues({ ...values, articleArray: getBookMarkList() })
    console.log("mount it!", articleArray)
  }, []) // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

  return (
    <Layout>
      <SEO title="Kalyan Feeds" />
      <div className="row backNavGreen">
        <div className="col-md-12" id="navCrumb">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row" id="breadCrumb">
                  <div className="col-12">
                    {" "}
                    {(() => {
                      if (child.length > 0) {
                        if (child[0].relationships.parent.length > 0) {
                          return (
                            <span>
                              <Link to="/">Home</Link>
                              &nbsp;/&nbsp;
                              <Link
                                to={child[0].relationships.parent[0].path.alias}
                              >
                                {child[0].relationships.parent[0].name}
                              </Link>
                              &nbsp;/&nbsp;
                              <Link to={child[0].path.alias}>
                                {child[0].name}
                              </Link>{" "}
                              &nbsp;/&nbsp;
                              <Link to={pageContext.CategoryPath}>
                                {pageContext.CategoryName}
                              </Link>
                            </span>
                          )
                        } else {
                          return (
                            <span>
                              <Link to="/">Home</Link>
                              &nbsp;/&nbsp;
                              <Link to={child[0].path.alias}>
                                {child[0].name}
                              </Link>
                              &nbsp;/&nbsp;
                              <Link to={pageContext.CategoryPath}>
                                {pageContext.CategoryName}
                              </Link>
                            </span>
                          )
                        }
                      } else {
                        return (
                          <span>
                            <Link to="/">Home</Link>
                            &nbsp;/&nbsp;
                            <Link to={pageContext.CategoryPath}>
                              {pageContext.CategoryName}
                            </Link>
                          </span>
                        )
                      }
                    })()}
                  </div>
                </div>
                <div className="row" id="headCrumb">
                  <div className="col-12">
                    {" "}
                    <h4 className="capitalise"> {pageContext.CategoryName}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div className="container inner-blog-page">
        <div className="row">
          {(() => {
            if (articleCount > 0) {
              let countForAds = limit;
              return articles.map((article, item) => {
                if(countForAds === item + 1 && REACT_APP_WITH_ADD) {
                  countForAds = countForAds  + range;
                  return <>
                  <div className="col-md-6 col-lg-3 mb-10">
                  <Banner
                      className="adsbygoogle"
                      style={{ display: 'block', width : '100%'}}
                      slot="1488237022"
                      format="auto"
                      responsive="true"
                    />
                   </div> 
                  <div className="col-md-6 col-lg-3"> 
                    <ArticlePreview
                      key={article.id}
                      articleId={article.drupal_id}
                      title={article.title}
                      path={article.path.alias}
                      image={
                        article.relationships.field_image.localFile
                          .childImageSharp.fluid
                      }
                      alt={article.field_image.alt}
                      tag={article.relationships.field_tags[0].name}
                      summary={
                        article.body.summary
                          ? article.body.summary
                          : article.body.processed.substring(0, 300)
                      }
                      isBookMarked={
                        articleArray.indexOf(article.drupal_id) != -1 && isAuth()
                          ? true
                          : false
                      }
                    />
                  </div>
                  </>
                }
                else {
                  return <> 
                  <div className="col-md-6 col-lg-3 mb-10"> 
                    <ArticlePreview
                      key={article.id}
                      articleId={article.drupal_id}
                      title={article.title}
                      path={article.path.alias}
                      image={
                        article.relationships.field_image.localFile
                          .childImageSharp.fluid
                      }
                      alt={article.field_image.alt}
                      tag={article.relationships.field_tags[0].name}
                      summary={
                        article.body.summary
                          ? article.body.summary
                          : article.body.processed.substring(0, 300)
                      }
                      isBookMarked={
                        articleArray.indexOf(article.drupal_id) != -1 && isAuth()
                          ? true
                          : false
                      }
                    />
                  </div>
                  </>
                }
              
              })
            } else {
              return (
                <div className="col-md-12">
                  <h4> Coming Soon ... </h4>
                </div>
              )
            }
          })()}
        </div>
      </div>
    </Layout>
  )
}

Wedding.propTypes = {
  data: PropTypes.object.isRequired,
}

export const data = graphql`
  query($CategoryId: String!) {
    articles: allNodeArticle(
      limit: 1000
      filter: {
        relationships: {
          field_tags: { elemMatch: { id: { eq: $CategoryId } } }
        }
      }
    ) {
      nodes {
        id
        drupal_id
        title
        created
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
              weight
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    tags: allTaxonomyTermKalyanShastraCategories(
      filter: { id: { eq: $CategoryId } }
    ) {
      edges {
        node {
          id
          name
          path {
            alias
          }
          relationships {
            parent {
              name
              path {
                alias
              }
              relationships {
                parent {
                  name
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Wedding
