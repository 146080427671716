import React, { useEffect } from 'react' 
import BannerInHouse from "../images/kalyan_matrimony.gif"
 

const Banner  = ({
  className,
  style,
  layout,
  format,
  client='ca-pub-2521366639965064',
  slot='1488237022',
  responsive,
  layoutKey,
}) => {
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
      console.log('adsbygoogle',adsbygoogle);
    } catch (e) {
      console.error(e)
    }
  }, [])
  return (
    <div className='google'>
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-layout={layout}
        data-ad-format={format}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout-key={layoutKey}
        data-full-width-responsive={responsive} 
      > 
      <a  target='_blank' href="https://www.kalyanmatrimony.com/online-matrimony-website?utm_source=google&utm_medium=cpc&utm_campaign=Ad_Brand-South&gclid=CjwKCAjwu5yYBhAjEiwAKXk_eGVCHTHwljaLUDV87mcLsdcPjnv2kerANME3GTwey2qk-rGR091wJRoCn8MQAvD_BwE">
         <img className="image-align" src={BannerInHouse} alt='' />{" "}
      </a>   </ins> 
    </div>
  )
}

export default Banner